export const translations = {
  en: {
    photos: 'Photos',
    videos: 'Videos',
    gallery: 'Gallery',
  },
  ge: {
    photos: 'Fotos',
    videos: 'Videos',
    gallery: 'Galerie',
  },
};
