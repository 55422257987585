import React from 'react';
import {
  SEO,
  Layout,
  Header,
  Footer,
  CustomTabs,
  PhotosGallery,
  VideosGallery,
  Container,
} from '../../components';
//
import cssModule from './index.module.scss';
import { translations } from './translations';

export default ({ pageContext: ctx }) => {
  const { images, videos, translationPath, metadata, i18n_lang: lang } = ctx;
  //
  const tabs = [translations[lang].photos, translations[lang].videos];
  return (
    <div className="gallery-page">
      <SEO {...metadata.metadata} />
      <Layout>
        <Header
          {...{ lang, heading: translations[lang].gallery }}
          lang={lang}
          translationPath={translationPath}
          className="gallery"
        />
        <section className={cssModule.gallery}>
          <Container>
            <CustomTabs
              tabs={tabs}
              panels={[<PhotosGallery images={images} />, <VideosGallery videos={videos} />]}
            />
          </Container>
        </section>
        <Footer lang={lang} />
      </Layout>
    </div>
  );
};
